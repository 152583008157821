<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAddCountryCode"
                    >
                        新增区域
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                ref="table"
                :default-sort="{prop: 'orderNum', order: 'ascending'}"
            >
                <el-table-column
                    prop="regionName"
                    label="地区名称"
                    min-width="100"
                />
                <el-table-column
                    prop="regionNameEn"
                    label="地区名称英文"
                    min-width="100"
                />
                <el-table-column
                    prop="regionCode"
                    label="地区代号"
                    min-width="100"
                />
                <el-table-column
                    prop="countryCode"
                    label="国际区号"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="排序"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :max="999999"
                            v-model.number="scope.row.orderNum"
                            @change="onOrderNumInput(scope.row)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="45"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onDeleteCountryCode(scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            title="新增地区"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddCountryCodeCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="addDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="addDialog.queryFormModel"
            >
                <el-form-item
                    prop="regionName"
                    label="地区名称"
                >
                    <el-input
                        v-model="addDialog.queryFormModel.regionName"
                        placeholder="请输入地区名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="regionNameEn"
                    label="地区名称英文"
                >
                    <el-input
                        v-model="addDialog.queryFormModel.regionNameEn"
                        placeholder="请输入地区名称英文"
                    />
                </el-form-item>
                <el-form-item
                    prop="regionCode"
                    label="地区代号"
                >
                    <el-input
                        v-model="addDialog.queryFormModel.regionCode"
                        placeholder="请输入地区代号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onAddDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetAddDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="addDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="countryCodeId"
                    ref="addDialogTable"
                    @selection-change="onAddDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="regionName"
                        label="地区名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="regionNameEn"
                        label="地区名称英文"
                        min-width="100"
                    />
                    <el-table-column
                        prop="regionCode"
                        label="地区代号"
                        min-width="100"
                    />
                    <el-table-column
                        prop="countryCode"
                        label="国际区号"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="addDialog.pagination"
                        @input="onAddDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddCountryCodeConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddCountryCodeCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'OrgCountryCode',
    mixins: [pagesMixin],
    data() {
        return {
            queryFormModel: {},
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    regionName: '', // 地区名称
                    regionNameEn: '', // 地区名称英文
                    regionCode: '', // 地区代码
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData() {
            return this.$api.Rs.OrgCountryCode.data({ orgId: this.$route.params.orgId }).then(json => {
                const res = json.data;
                this.tableData = res.data;
            });
        },
        /* -- 事件 -- */
        onAddCountryCode() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                this.onAddDialogQuery();
                const { addDialogTable } = this.$refs;
                // 初始化已选择数据
                if (addDialogTable) {
                    addDialogTable.clearSelection();
                }
                // this.tableData = [this.addDialog.tableData[0]];
                this.tableData.forEach(item => {
                    addDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onAddDialogQuery(pagination) {
            return this.$api.Rs.CountryCode.data({
                ...this.addDialog.queryFormModel,
                ...pagination,
                isAvailable: 1,
            }).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    item.countryCodeId = item.id;
                    item.id = null;
                });
                this.addDialog.tableData = res.data;
                this.addDialog.pagination = res.pagination;
            });
        },
        onAddDialogSelectionChange(val) {
            this.addDialog.currentTableSelect = val;
        },
        onResetAddDialog() {
            this.$refs.addDialogQueryForm.resetFields();
        },
        onAddCountryCodeConfirm() {
            this.$api.Rs.OrgCountryCode.save({
                orgId: this.$route.params.orgId,
                jsonStr: JSON.stringify(this.addDialog.currentTableSelect),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.getListData();
                this.addDialog.isVisible = false;
            });
        },
        onAddCountryCodeCancel() {
            this.addDialog.isVisible = false;
        },
        onDeleteCountryCode(row) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Rs.OrgCountryCode.delete({ ids: row.id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.getListData();
                });
            });
        },
        onOrderNumInput(row) {
            const { table } = this.$refs;
            this.$api.Rs.OrgCountryCode.changeOrgCountryCodeOrderNum({ id: row.id, orderNum: row.orderNum }).then(
                () => {
                    table.sort();
                },
            );
        },
    },
    created() {
        this.getListData();
    },
};
</script>

<style lang="scss">
</style>
